<template>
  <div
    v-if="channelsFav.length"
    class="titleRow"
  >
    Мои каналы
  </div>

  <ChannelSwiper
    :channels="channelsFav"
    local-storage-key="channelsFavList"
  />
</template>

<script setup>
import ChannelSwiper from '@/components/ui/Swipers/ChannelSwiper';

if (process.client) {
  onMounted(async () => {
    await channelsStore.fetchChannels();
  });
}

const channelsStore = useChannelsListStore();
const { channelsFav } = storeToRefs(channelsStore);
</script>
